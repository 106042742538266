.dropdownTimer {
    margin: 0 auto;
    /* z-index: 10000; */
    width: 118px;
    position: relative;
}
.dropdownTimer,
.dropdownTimer * {
    /* z-index: 10; */

    background-color: #fff;
    position: relative;
}
.dropdownTimer .dropdownTimer-btn {
    height: 39px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 11px;
    color: #4f4f4f;
    font-weight: 400;
    line-height: 26px;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    z-index: 10;
}
.dropdownTimer-content {
    position: absolute;
    font-weight: 400;
    left: 0;
    width: 100%;
    overflow-y: auto;
    height: 300px;
    box-shadow: 0 0 10px 5px rgba(40, 38, 38, 0.07);
    line-height: 26px;
    font-size: 16px;
    color: #4f4f4f;
    font-family: 'Open Sans';
    font-style: normal;
    z-index: 1000;
}
.dropdownTimer-content .itemTimer {
    padding: 5px;
    cursor: pointer;
}
.dropdownTimer-content .itemTimer:hover {
    background: #c8175d;
}
.sidebarIcon:hover {
    // color: #ff9922 !important;
}
.css-qmi47k-MuiTypography-root {
    // color: #364152 !important;
}

.css-19midj6 {
    padding: 10px !important;
}
.css-le99rq-MuiList-root {
    width: 100% !important;
    max-width: none !important;
    min-width: 136px !important;
}
.css-105kjsu-MuiButtonBase-root-MuiListItemButton-root:hover {
    // padding: 2px 2px 2px 11px;
}
.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
    margin-top: 8px !important;
}
.css-1rv3ei8-MuiFormControl-root-MuiTextField-root {
    margin-top: 8px !important;
    width: 216px !important;
}
.css-1tl47u6 {
    margin: 8px 0px 8px !important;
}
